import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner.png'
import img01 from '../../assets/img/Work04/work04_01.png'
import img02 from '../../assets/img/Work04/work04_02.jpg'
import img03 from '../../assets/img/Work04/work04_03.png'
import square from '../../assets/img/Work01/square.png'
import btn from '../../assets/img/Work01/work01btn.png'


function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },

    fontsize: {
      marginTop: '-3%',
      paddingLeft: '4.5%',
      color: '#b3b3b3',
    },
    tableSize: {
      align: 'center',
      borderCollapse: 'collapse',
      marginTop: '6%',
      width: '100%',
      height: '100%',
    },
    spanStyle:{
      width: '2%',
      height: '50%',
      paddingBottom: '1%'
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        <script type="text/javascript" src="/jquery.mCustomScrollbar.css"></script>
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script>
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{  color: 'crimson'}}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　SI 事業</dt>
                <dd>
                  <a href="/work01">開発</a>
                </dd>
                <dd>
                  <a href="/work02"> ネットワーク<br></br>セキュリティ</a>
                </dd>
                <dd>
                  <a href="/work03">モバイル開発</a>
                </dd>
                <dd>
                  <a href="/work04">システム</a>
                </dd>
                <dd>
                  <a href="/work05">データベース</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
              <h2> 

              <div className="workSquarediv"><img src={square} className="Square"></img></div>
                  <div className="workTitlediv"> Cloud(AWS・Azure・GCP)・OpenStack・VMware<br></br>AIX、Linux・Windows～</div>
                 
               </h2>
              <div >
                  <h3 style={style.fontsize}>
                    {' '}
                    システムのコンサル・導入・設計・構築・検証・運用・
監視し、安全なインフラを守り、社会づくりに貢献します。
                  </h3>
                </div>
                <div className="work04Img01div">
                  <img src={img01} className="work04Img01" />
                </div>
                <div className="work04Img02div">
                  <img src={img02} className="work04Img02" />
                </div>
                <div className="work04Tablediv">
                <table style={style.tableSize}>
                <tr className ='workTableCss'>
                      <td className ='workTablefirsttd' align="center">AIX</td>
                      <td className="workTableCss" align="center">
                      Ｕnix
                      </td>
                      <td className="workTableCss" align="center">
                      Linux
                      </td>
                      <td className="workTableCss" align="center">
                      Windows
                      </td>
                      <td className="workTableCss" align="center">
                      Vmware
                      </td>
                      <td  className ='workTablefirsttd' align="center">
                      AWS
                      </td>
                    </tr>
                    <tr className="workTableCss">
                      <td  className ='workTablefirsttd' align="center">
                      Azure
                      </td>
                      <td className="workTableCss" align="center">
                      GCP
                      </td>
                      <td className="workTableCss" align="center">
                      JP1
                      </td>
                      <td className="workTableCss" align="center">
                      Tivoli
                      </td>
                      <td className="workTableCss" align="center">
                      ActiveDirectory
                      </td>
                      <td className="workTablefirsttd" align="center">
                      Systemwalker
                      </td>
                    </tr>
                    <tr className="workTableCss">
                      <td  className ='workTablefirsttd' align="center">
                      Zabbix
                      </td>
                      <td className="workTableCss" align="center">
                      Docker
                      </td>
                      <td className="workTableCss" align="center">
                      Splunk
                      </td>
                      <td className="workTableCss" align="center">
                      OpenStack
                      </td>
                      <td className="workTableCss" align="center">
                      Shell
                      </td>
                      <td className="workTablefirsttd" align="center">
                      ActiveDirectory
                      </td>
                    </tr>

                  </table>


                </div>

                <div className="work04Img03div" style={{marginBottom:'-1%'}} >
                    <img src={img03} className="work04Img03" />  
                </div>
                <a href="/contact" >
                <img src={btn} className="workBtn"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
